/* Base Styles */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
}

.no-touch {
  touch-action: none;
}

header {
  height: 70px;
  position: block;
  width: 100%;
}

button {
  font-family: "Poppins", sans-serif;
}

a {
  text-decoration: none;
}

/* Nav */
.nav-area {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: #212529;
  background-color: #fff;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 10px 20px;
  height: 70px;
  width: 100%;
}

.hamburger {
  display: none;
}

.logo {
  text-decoration: none;
  font-size: 25px;
  color: inherit;
}

.menus {
  align-items: center;
  list-style: none;
}

.menu-items {
  position: relative;
  font-size: 18px;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
}

button span {
  margin-left: 3px;
}

.menu-items > a,
.menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 9999;
  min-width: 10rem;
  padding: 0.5rem 0;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
}

.dropdown.show {
  display: block;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  margin-right: 10px;
}

/* TEMP!!! */
#about1,
#about2,
#about3,
#about4 {
  height: 600px;
  width: 100%;
  background-color: olive;
  margin: 0;
}

@media screen and (min-width: 320px) {
  /* Base Styles */
  section {
    padding: 10px 5px 30px;
  }

  h1 {
    font-size: 24px;
    font-weight: 600;
    margin: 10px 0 10px 0;
  }

  h2 {
    font-size: 20px;
    font-weight: 600;
    margin: 8px 0;
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    margin: 8px 0;
  }

  p,
  li {
    font-size: 16px;
  }

  .btn {
    background-color: #354347;
    color: white;
    padding: 16px 16px;
    display: block;
    margin: 2rem auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    text-decoration: none;
  }

  .hidden {
    display: none;
  }

  /* Nav */

  .nav-area {
    padding: 5px 7px;
    position: relative;
  }

  .logo-short {
    color: #354347;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 3px;
    line-height: 20px;
    text-transform: uppercase;
  }

  .logo-long {
    display: none;
  }

  .hamburger-container {
    background-color: rgba(255, 255, 255, 0.5);
    height: 50px;
    width: 50px;
    display: block;
    position: fixed;
    right: 2px;
    top: 2px;
    z-index: 100;
    border-radius: 4px;
  }

  .hamburger {
    height: 50px;
    width: 50px;
    filter: invert(21%) sepia(3%) saturate(3000%) hue-rotate(147deg)
      brightness(95%) contrast(83%);
    z-index: 100;
    display: block;
    position: fixed;
    right: 2px;
    top: 2px;
  }

  .white {
    filter: invert(98%) sepia(0%) saturate(6932%) hue-rotate(143deg)
      brightness(113%) contrast(90%);
  }

  .menus {
    flex-direction: column;
    position: fixed;
    transform: translateY(-100%);
    transition: transform 0.4s;
    width: 100%;
    height: 150%;
    background: rgba(160, 101, 45, 1);
    left: 0;
    top: 0;
    z-index: 98;
    padding: 2rem 0;
  }

  .showMenus {
    transform: translateY(0);
  }

  .dropdown {
    position: relative;
    border-radius: 0;
    box-shadow: inset 0 0 5px 3px rgba(0, 0, 0, 0.2);
  }

  .nav-area {
    justify-content: space-between;
  }

  .menu-items > a,
  .menu-items button {
    text-align: center;
    justify-content: center;
    font-size: 18px;
  }

  .menu-items > a,
  .menu-items > button {
    color: #f2f2f2;
  }

  .menu-items .dropdown a {
    color: #212529;
    font-weight: 400;
  }

  .menus .menu-items {
    width: 100%;
  }

  /* Hero Section */
  #hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 40vh;
    overflow: hidden;
    background-position: center center;
    background-size: cover;
    position: relative;
  }

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .hero-text {
    font-size: 30px;
  }

  .prevBtn {
    position: absolute;
    top: 51vh;
    left: 2px;
  }

  .nextBtn {
    position: absolute;
    top: 51vh;
    right: 2px;
  }

  .prevBtn,
  .nextBtn {
    background-color: transparent;
    border: none;
    color: #f2f2f2;
  }

  .quote-container {
    position: relative;
    background-color: rgba(0, 0, 0, 0.7);
    color: #f2f2f2;
    text-align: center;
    padding: 20px 12px;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .quote-text {
    font-size: 18px;
  }

  .attribution {
    font-size: 12px;
    margin-top: 6px;
  }

  /* Services */

  .about {
    background-color: #f5f4ef;
  }

  .about h1 {
    text-align: center;
    color: #a0652d;
  }

  #services .row {
    align-items: stretch;
  }

  .about-left,
  .about-middle,
  .about-right {
    flex-basis: 32%;
    padding: 0 2rem;
    width: 100%;
    border: 1px solid #a0652d;
    position: relative;
    padding-bottom: 100px;
    height: auto;
    background-color: #f2f2f2;
    border-radius: 4px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 10px;
  }

  .about h2 {
    color: #a0652d;
    text-align: center;
  }

  .about li {
    margin-left: 20px;
    padding-top: 2px;
  }

  .toggle-view {
    color: #a0652d;
  }

  .toggle-view:hover {
    cursor: pointer;
  }

  .toggle-view::marker {
    color: black;
  }

  .hidden-items li::marker {
    color: red;
    content: "• ";
  }
  .list-link {
    color: #a0652d;
  }

  .list-link:hover {
    color: black;
    transition: 0.5s ease-in-out;
  }

  #services button {
    position: absolute;
    bottom: 0%;
    margin-left: 1rem;
    margin-right: 1rem;
    left: 0;
    right: 0;
    text-align: center;
  }

  .btn:hover {
    background-color: #a0652d;
    transition: 0.5s ease-in-out;
  }

  /* About Us*/

  .about2 {
    background-color: #354347;
    color: white;
  }

  .about2 h1 {
    text-align: center;
    margin-top: 0;
  }

  .staff-card {
    margin: 20px auto 40px;
    align-items: flex-start;
  }

  .bio-container {
    padding: 0 10px;
  }

  .headshot-container {
    margin: 0 0 10px;
    display: flex;
    justify-content: center;
  }

  .headshots {
    border-radius: 50%;
    height: 180px;
    width: auto;
  }

  .about2 h3 {
    font-weight: 200;
    letter-spacing: 2px;
  }

  /* Process Section */

  .process a {
    color: #a0652d;
  }

  .process a:hover {
    color: black;
    transition: 0.5s ease-in-out;
  }

  .process-title {
    text-align: center;
  }

  .process-title h1 {
    color: #87836b;
  }

  .process-content h2 {
    color: #a0652d;
  }

  .process-content h3 {
    color: #87836b;
    text-align: left;
  }

  .process .row {
    justify-content: center;
    width: 100%;
  }

  .process-step-numbers {
    display: none;
  }

  .progress-bar {
    width: 80%;
    height: 2px;
    background-color: #222;
    margin: auto;
    position: relative;
    z-index: -50;
  }

  .progress-bar .c1,
  .progress-bar .c2,
  .progress-bar .c3 {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #222;
    position: absolute;
  }

  .progress-bar .c1 {
    top: -8px;
    left: 50px;
  }

  .progress-bar .c2 {
    top: -8px;
    left: 50%;
  }

  .progress-bar .c3 {
    top: -8px;
    right: 50px;
  }

  .box1,
  .box2,
  .box3 {
    padding: 10px;
    width: auto;
    height: auto;
  }

  .process .box1,
  .process .box2,
  .process .box3 {
    text-align: center;
  }

  .box1 button {
    margin-bottom: 0;
    text-decoration: none !important;
  }

  .progress-bar {
    display: none;
  }

  .process-content {
    margin-top: 0;
    margin-bottom: 0;
  }
  .process-content .row {
    flex-direction: column;
  }

  .process-content h2 {
    text-align: left;
  }

  .process-content p {
    text-align: left;
  }

  .process-step-numbers {
    display: inline-block;
    font-size: 2.4rem;
    font-weight: 800;
    color: #a0652d;
  }

  .testimony .row {
    flex-direction: column;
  }

  /* FAQ Section*/

  .faq-content {
    padding: 10px;
  }

  .faq {
    background-color: #eceadd;
    color: #354347;
  }

  .faq h1 {
    color: #a0652d;
    text-align: center;
  }

  .faq h2 {
    color: #a0652d;
  }

  .faq h3 {
    color: #87836b;
  }

  .faq p {
    margin-bottom: 20px;
  }

  .faq p:last-child {
    margin-bottom: 0;
  }

  /* Contact Section */

  .contact {
    background-color: #354347;
    padding: 15px;
  }
  .contact h1 {
    color: #fff;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  #contact .row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }

  .contact-left {
    flex-basis: 50%;
    color: white;
    height: auto;
  }

  .contact-left h2 {
    font-weight: lighter;
    letter-spacing: 0px;
    text-transform: uppercase;
  }

  .contact-right {
    flex-basis: 50%;
    height: auto;
  }

  .contact form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .contact form input {
    width: 100%;
  }

  .contact textarea {
    width: 100%;
    height: 150px;
    padding: 12px 20px;
    border: 1px solid #87836b;
    font-size: 1.3rem;
    resize: none;
    color: #ccc;
    margin: 1.2rem 0;
    font-family: "Poppins", sans-serif;
    border-radius: 4px;
  }

  .contact p {
    margin: 0;
  }

  .contact h3 {
    font-weight: 600;
  }

  .contact a {
    font-size: 16px;
    color: white;
  }

  .google-map {
    border-radius: 4px;
    box-shadow: 2px 5px 2px rgba(0, 0, 0, 0.2);
    margin: 2rem 0;
    width: 100%;
    height: 250px;
  }

  /* Footer */

  footer {
    height: 80px;
    background-color: #222;
    color: white;
    text-align: center;
    padding: 1rem;
  }

  footer a,
  footer p {
    color: white;
    font-size: 12px;
  }

  footer a:hover {
    color: #a0652d;
    transition: 0.5s ease-in-out;
  }

  /* Appointment Form */
  .appointment-form-section {
    background-color: #f5f4ef;
    display: flex;
    justify-content: center;
  }

  .appointment-form-section h1 {
    text-align: center;
    color: #a0652d;
    margin: 20px 0;
  }

  .form-container {
    background-color: white;
    width: 45%;
    margin: 0 auto;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .form-container h2 {
    text-align: center;
    margin: 2rem 0;
  }

  .appt-req-form input {
    display: inline-block;
    height: 40px;
    border-radius: 4px;
    padding: 5px;
    margin: 0.5rem;
  }

  .appt-req-form {
    max-width: 400px;
    margin: 0 auto 20px;
  }

  .form-row {
    display: flex;
    justify-content: center;
  }

  .form-row-checkbox {
    display: flex;
    justify-content: flex-start;
  }

  label {
    width: 100%;
    margin: 3px 0;
  }

  .form-row-services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 8px;
  }

  .disclaimer {
    font-size: 14px;
    color: grey;
    margin: 8px 0;
  }

  .your-contact-info {
    border: 1px solid grey;
    padding: 10px;
    margin: 10px;
    border-radius: 4px;
  }

  .form-row {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .form-row-checkbox {
    display: flex;
    justify-content: flex-start;
  }

  .form-row-services {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .your-contact-info {
    display: flex;
    flex-direction: column;
  }

  input[type="checkbox"] {
    height: 16px;
    width: 16px;
  }

  /* input[type="text"],
  input[type="email"],
  input[type="date"] {
    width: 100%;
    margin: 5px 0;
    font-size: 16px;
    border: 1px solid grey;
  } */

  input[type="submit"] {
    width: 100%;
    display: block;
    margin: 1rem auto;
    height: 4rem;
  }

  input[type="date"] {
    background-color: transparent;
    border: 1px solid grey;
    color: grey;
  }

  .appointment-form-section {
    padding: 0;
  }

  .form-container {
    margin: 0;
    width: 100%;
  }

  .form-container textarea {
    width: 100%;
    height: 300px;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    resize: none;
    padding: 5px;
    border-radius: 4px;
  }

  .error-message {
    color: red;
    text-align: left;
    font-size: 12px;
    width: 100%;
  }

  .input-field {
    width: 100%;
    margin: 5px 0;
    font-size: 16px;
    border: 1px solid grey;
  }

  .my-input-error {
    border: 1px solid red;
  }

  /* Fees */
  .fee-table-wrapper table,
  .fee-table-wrapper thead,
  .fee-table-wrapper tbody,
  .fee-table-wrapper th,
  .fee-table-wrapper td,
  .fee-table-wrapper tr {
    display: block;
  }

  .fee-table-wrapper tr {
    border: 1px solid #a0652d;
    border-radius: 4px;
    overflow: hidden;
  }

  .row-odd,
  .row-even {
    margin: 20px 12px 20px;
  }

  .fee-table td:first-child {
    background-color: #a0652d;
    color: white;
    font-weight: 600;
  }

  .fee-table td:nth-child(3) {
    font-style: italic;
  }

  .fee-table .table-header {
    display: none;
  }

  .fee-table-wrapper td {
    padding: 6px 16px;
  }

  .fees-section .btn {
    width: 95%;
    margin: 20px auto;
  }

  .fees-section h1 {
    text-align: center;
    color: #354347;
  }

  .no-surprise-container {
    margin: 12px;
    border-radius: 4px;
    border: 1px solid #354347;
    overflow: hidden;
  }

  .no-surprise-text {
    padding: 6px;
  }

  .no-surprise-text p {
    margin: 10px;
  }

  .fees-section h2 {
    cursor: pointer;
    text-align: center;
    color: white;
    background-color: #354347;
    margin: 0;
    padding: 4px;
  }

  .read-more {
    color: #a0652d;
    cursor: pointer;
  }

  .read-more:hover {
    color: #354347;
    transition: 0.2s ease-in;
  }
}

@media screen and (min-width: 425px) {
  #services .btn {
    max-width: 300px;
    margin: 2rem auto;
  }
}

@media screen and (min-width: 600px) {
  .submission-card {
    display: flex;
  }

  .logo-short {
    display: none;
  }

  .logo-long {
    display: block;
    color: #354347;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

  .box1 .btn {
    margin-left: 0;
  }

  #contact .row {
    flex-direction: row;
  }

  .contact-right .google-map {
    margin: 0;
  }

  section {
    padding: 10px 10% 30px;
  }

  .contact h2 {
    margin-top: 0;
  }

  .quote-text {
    max-width: 600px;
    font-size: 22px;
  }
}

@media screen and (min-width: 820px) {
  .contact {
    padding: 10px 5% 30px;
  }
}

@media screen and (min-width: 1024px) {
  /* Fees Page */
  .fee-table .table-header {
    display: table-row;
  }

  .fee-table-wrapper table {
    display: table;
  }

  .fee-table-wrapper tbody {
    display: table-row-group;
  }

  .fee-table-wrapper th {
    display: table-cell;
  }
  .fee-table-wrapper td {
    display: table-cell;
  }

  .fee-table-wrapper tr {
    display: table-row;
  }

  .fees-section h1 {
    color: #a0652d;
    text-align: center;
  }

  .fee-table-wrapper {
    margin: 12px;
    background-color: #f2f2f2;
    padding: 0px;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .fee-table {
    width: 100%;
    margin: 0 auto;
    background-color: black;
    border-radius: 4px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .table-header {
    background-color: #354347;
    color: white;
  }

  .table-header th {
    font-weight: 600;
    letter-spacing: 2px;
  }

  .fee-table td,
  .fee-table th {
    font-size: 16px;
    padding: 0.5rem;
  }

  .row-even,
  .row-odd {
    background-color: #f1f1f1;
  }

  .extra-info {
    display: none;
  }

  .fees-section .btn {
    width: auto;
    margin-right: 10px;
  }

  /* .form-row {
    flex-direction: row;
  } */

  /* .appt-req-form {
    max-width: 1024px;
    width: 50%;
  } */
  input[type="text"],
  input[type="email"] {
    margin: 5px;
  }

  input[type="submit"] {
    margin: 1rem auto;
  }

  .form-row-checkbox input[type="text"] {
    width: 40%;
  }

  input[type="date"] {
    width: 40%;
    margin: 5px;
  }

  .about .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  h1 {
    font-size: 30px;
  }

  .about-left,
  .about-middle,
  .about-right {
    padding: 8px 12px 100px;
  }

  .about .btn {
    width: 95%;
  }

  .contact {
    padding: 10px 10% 30px;
  }

  .contact .row {
    padding: 0 5px;
  }

  .hamburger-container {
    display: none;
  }

  nav .menus {
    display: flex;
    translate: none;
    flex-direction: row;
    position: relative;
    transform: none;
    transition: none;
    left: auto;
    top: auto;
  }

  .menus {
    display: flex;
    flex-direction: row;
    height: auto;
    width: auto;
    padding: 0;
    background-color: transparent;
  }

  .menu-items {
    font-size: 18px;
  }

  .menu-items a {
    display: block;
    font-size: inherit;
    color: rgb(0, 0, 0);
    text-decoration: none;
  }

  .menu-items button {
    align-items: center;
    font-size: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  button span {
    margin-left: 3px;
  }

  .menu-items > a,
  .menu-items button {
    text-align: left;
    padding: 0.7rem 1rem;
    color: #212529;
  }

  .menu-items a:hover,
  .menu-items button:hover {
    background-color: #f2f2f2;
  }

  .arrow::after {
    content: "";
    display: inline-block;
    margin-left: 0.28em;
    vertical-align: 0.09em;
    border-top: 0.42em solid;
    border-right: 0.32em solid transparent;
    border-left: 0.32em solid transparent;
  }

  .dropdown {
    position: absolute;
    right: 0;
    left: auto;
    box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
      0 4px 6px -2px rgba(71, 63, 79, 0.16);
    font-size: 0.875rem;
    z-index: 9999;
    min-width: 10rem;
    padding: 0.5rem 0;
    list-style: none;
    background-color: #fff;
    border-radius: 0.5rem;
    display: none;
  }

  .dropdown.show {
    display: block;
  }

  .dropdown .dropdown-submenu {
    position: absolute;
    left: 100%;
    top: -7px;
  }

  .staff-card {
    display: flex;
  }

  .hidden {
    display: none;
  }

  .nav-area {
    position: fixed;
    z-index: 9999;
  }

  #hero {
    height: 60vh;
  }

  * {
    scroll-padding-top: 70px;
  }
}

.submission-card {
  border: solid #212529;
  border-radius: 4px;
  margin: 8px;
  padding: 4px;
}

.submission-section {
  padding: 4px;
  width: 100%;
}
